[data-topic-page] {
  .shareBar {
    margin: 0 0 10px;

    :global(.comments-count) {
      margin: 7.2px 0 0;
    }
  }
}

:global(article) {
  [data-topic-page] {
    .shareBar {
      :global(.comments-count) {
        margin: 7.2px 0 0;
      }
    }
  }
}
