[data-topic-page] {
  .infiniteScroll {
    h4 {
      background-color: #f1f1f1;
      text-align: center;
      padding: 14px 0;
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 15px;
    }
  }
}
