[data-topic-page] {
  .title {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    text-transform: uppercase;
    padding: 4px 3px 3px 6px;
    margin-bottom: 15px;
    font-weight: bold;
    font-size: 15.6px;

    &.mailplus {
      border-radius: 0;
      background: linear-gradient(118deg, #1e3264 4.18%, #004db3 64.65%);
    }
  }
}
