[data-topic-page] {
  .pagination {
    display: flex;
    padding: 2px 8px 8px;
    border-bottom: 1px solid #ccc;
    margin: 8px 0 10px;

    > div {
      width: 60%;
      font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
      font-size: 15px;
    }

    ul {
      display: flex;
      justify-content: flex-end;
      width: 40%;
      margin: 0;
      padding: 0;

      li {
        list-style: none;
        margin: 0;
        padding: 0 5px;

        a {
          color: #004db3;
          font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
          font-size: 15px;
          font-weight: normal;
        }

        &:global(.disabled) a {
          opacity: 0;
          cursor: default;
        }

        &:not(:global(.previous)):not(:global(.next)) {
          display: none;
        }
      }
    }
  }
}
