[data-topic-page] {
  .title {
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    color: white;
    background-color: black;
    padding: 8px 10px;
    margin: 0 0 10px;
    font-size: 16px;
    font-weight: normal;
    line-height: normal;

    &.mailplus {
      background: linear-gradient(118deg, #1e3264 4.18%, #004db3 64.65%);
    }
  }
}
