[data-topic-page] {
  .pagination {
    display: flex;
    margin: 0 auto;
    width: 80%;
    justify-content: center;

    .paginationContent {
      display: flex;

      span {
        font-size: 14px;
        padding: 3px;
        margin-right: 6px;
      }

      ul {
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: space-evenly;
        width: 100%;

        li {
          list-style: none;
          margin: 0 6px 0 0;
          padding: 0;
          width: 100%;
          max-width: 35px;

          a {
            text-decoration: none;
            cursor: pointer;
            border: 1px solid #c0c0c0;
            padding: 3px;
            display: block;
            color: #004db3;
            font-weight: bold;
            font-size: 14px;
            text-align: center;

            &:hover {
              background-color: #f2f2f3;
            }
          }

          &:global(.active) {
            a {
              background-color: #e3e3e3;
              color: black;
            }
          }

          &:global(.previous),
          &:global(.next) {
            a {
              border: none;
              font-weight: normal;

              &:hover {
                background-color: inherit;
              }
            }

            &:global(.disabled) {
              display: none;
            }
          }

          &:last-of-type {
            margin-right: 0;
          }
        }
      }
    }
  }
}
