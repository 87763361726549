[data-topic-page] {
  .mobileApp {
    font-family: Arial, Helvetica, sans-serif;
    position: relative;
    margin: 0 0 15px 0;
  }

  .disableAllPointerEvents {
    pointer-events: none;
  }
}
