[data-topic-page] {
  .article {
    margin: 0 0 10px;
    border-top: 0;
    border-bottom: 1px solid #ccc;
    list-style: none;

    a {
      text-decoration: none;

      h2 {
        font-family: system-ui, -apple-system, sans-serif;
        font-size: 22px;
        font-weight: bold;
        padding: 0 8px 10px;
        color: #004db3;
        margin: 0;
        line-height: 26px;

        :global(.is-paywalled) {
          border-radius: 5px;
          margin-right: 0.188rem !important;
          top: -1px;
        }
      }

      .imgWrapper {
        margin: 0 2px;
        position: relative;

        .imgIconWrapper {
          margin: 0;
          padding: 0;
          position: absolute;
          bottom: 10px;
          right: 10px;
          box-sizing: border-box;
          display: table;
          height: 36px;
          line-height: 34px;
          font-size: 16px;
          text-align: left;
          background-position: 0;
          background-repeat: no-repeat;
          background-color: rgba(0, 0, 0, 0.4);
          border-radius: 3px;
          color: #fff;

          .imgIcon {
            margin: 0;
            padding: 0;
            background: url(//i.dailymail.co.uk/i/furniture/mobile/icon_camera_90x68.png) no-repeat 10px;
            height: 20px;
            width: 24px;
            padding-top: 7px;
            padding-left: 10px;
            background-size: 27px;
            -webkit-box-sizing: content-box;
            box-sizing: content-box;
            padding-right: 10px;
            float: none;
            display: table-cell;
            vertical-align: middle;
          }

          span {
            padding-right: 10px;
            float: none;
            display: table-cell;
            vertical-align: middle;
            padding-top: 2px;
          }
        }

        img {
          width: 100%;
          display: block;
        }
      }

      p {
        color: black;
        font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
        font-size: 14px;
        max-height: 131.6px;
        overflow: hidden;
        padding: 0 8px;
        margin: 8px 0 4px;
        line-height: 19.6px;
        font-weight: normal;
        letter-spacing: -0.16px;

        .sponsored {
          color: #0cf;
          font-weight: bold;
          text-transform: uppercase;
          margin-right: 4px;
        }
      }
    }

    &:last-of-type {
      border-bottom: none;
    }
  }
}

:global(article) {
  [data-topic-page] {
    .article {
      h2,
      p {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}
