[data-topic-page] {
  .mainArticle {
    margin: 0 0 20px;

    h2 {
      font-size: 30px;
      margin-bottom: 10px;
      line-height: normal;
      letter-spacing: normal;

      a {
        text-decoration: none;
      }

      :global(.is-paywalled) ~ a {
        margin-left: 0.188rem;
      }

      :global(.is-paywalled) {
        border-radius: 5px;
        top: -2px;
      }
    }

    .container {
      display: flex;

      .leftCol {
        width: 48%;

        img {
          width: 100%;
        }
      }

      .rightCol {
        width: 50%;
        padding: 0 0 8px 10px;

        .firstPubDate {
          font-size: 12px;
          font-weight: bold;
          margin-bottom: 5px;
        }

        .previewText {
          font-size: 12px;
          margin-bottom: 10px;
          letter-spacing: normal;
          line-height: normal;
        }
      }
    }
  }
}
