[data-topic-page] {
  .article {
    margin: 0 0 15px;
    padding: 0;

    display: flex;

    .leftCol {
      width: 14%;

      img {
        width: 100%;
      }
    }

    .rightCol {
      width: 86%;
      padding: 0 0 8px 10px;

      h2 {
        font-size: 15.6px;
        margin: 0 0 5px;
        line-height: normal;
        letter-spacing: normal;

        a {
          text-decoration: none;
        }

        :global(.is-paywalled) ~ a {
          margin-left: 0.188rem;
        }

        :global(.is-paywalled) {
          border-radius: 5px;
          height: 1rem;
          top: -1px;
        }
      }

      .firstPubDate {
        font-size: 12px;
        font-weight: bold;
        margin-bottom: 5px;
      }

      .previewText {
        font-size: 12px;
        margin-bottom: 5px;
        letter-spacing: normal;
        line-height: normal;

        .sponsored {
          color: #0cf;
          font-weight: 700;
          text-transform: uppercase;
          margin-right: 4px;
        }
      }
    }
  }
}
