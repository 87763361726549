[data-topic-page] {
  .app {
    font-family: Arial, Helvetica, sans-serif;
    position: relative;
    margin: 0 0 15px;

    .topPagination {
      margin: 0 0 15px;
      border-bottom: 1px solid #ccc;
      padding: 0 0 15px;
    }

    .content {
      margin: 0;
      padding: 0;
    }

    .bottomPagination {
      margin: 15px 0;
      border-top: 1px solid #ccc;
      padding: 15px 0 0;
    }
  }

  .disableAllPointerEvents {
    pointer-events: none;
  }
}
